import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";
import ConferenceTable from "./components/ConferenceTable";
import SearchBar from "./components/SearchBar";
import FilterDropdown from "./components/FilterDropdown";

const App = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get("https://cfpapi.tuhidulhossain.com/api/data")
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  // Handle search
  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue.toLowerCase());

    const filtered = data.filter(
      (item) =>
        item.Title.toLowerCase().includes(searchValue) ||
        item.Acronym.toLowerCase().includes(searchValue)
    );
    setFilteredData(filtered);
  };

  // Handle filter
  const handleFilter = (filterValue) => {
    setFilter(filterValue);

    if (filterValue === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => item.Where.includes(filterValue));
      setFilteredData(filtered);
    }
  };

  const uniqueLocations = [...new Set(data.map((item) => item.Where))];

  return (
    <Container>
      <h1 className="text-center my-4">Conference Dashboard</h1>

      <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
      <FilterDropdown
        locations={uniqueLocations}
        selectedLocation={filter}
        handleFilter={handleFilter}
      />

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <ConferenceTable data={filteredData} />
      )}
    </Container>
  );
};

export default App;